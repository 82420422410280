legend.MuiFormLabel-root,
p.MuiFormLabel-root {
    text-align: center;
    font-size: 1.5em;
    color: var(--primary-color);
    margin-top: 8px;
    /* margin-bottom: 8px; */
}

.MuiGrid-root.MuiGrid-item.formInquiry {
    margin-bottom: 8px;
}

/* form numbers before the inquiry */
legend.MuiFormLabel-root::before {
    content: var(--form-num);
    display: inline-flex;
    border: 1px solid;
    width: 35px;
    height: 35px;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
}

.animatedFormGroup {
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* width: 100vw; */
    min-height: 70vh;
    will-change: transform, opacity;
    top: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    margin-top: 20px;
}
.form-group {
    padding-bottom: 80px;
}

.MuiButtonBase-root.Mui-disabled {
    opacity: 0.5;
}

.MuiButtonBase-root.Mui-disabled + .MuiTouchRipple-root {
    display: none;
    opacity: 0;
}

.MuiPickersDay-root.MuiButtonBase-root.Mui-disabled {
    /* trying to style disable calendar dates */
    background-color: #7a949229;
    border: 1px dotted var(--primary-color);
}

.MuiSwitch-root .MuiButtonBase-root.Mui-disabled {
    background-color: transparent;
    border: none;
}

.MuiPickersArrowSwitcher-root .MuiButtonBase-root.Mui-disabled {
    background-color: transparent;
    border: none;
    filter: blur(1px);
}

.animatedFormGroup .MuiGrid-root.MuiGrid-item {
    /*I don't know what I was doing with this ~April 15, 2022*/
    /* padding-right: 0; */
}

@media (max-width: 700px) {
    .animatedFormGroup {
        top: 170px;
        padding-top: 0;
    }
    .form .form-group {
        margin-top: 0;
    }
    legend.MuiFormLabel-root,
    p.MuiFormLabel-root {
        text-align: center;
        font-size: 1.3em;
        color: var(--primary-color);
        margin-top: 8px;
        /* margin-bottom: 8px; */
    }
}
